<template>
  <Fieldset legend="ประวัติการเข้าใช้งานระบบ">
    <DataTable
      :value="activities"
      ref="dt"
      dataKey="id"
      :paginator="true"
      :rows="20"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      :rowsPerPageOptions="[20, 50, 100]"
      currentPageReportTemplate="แสดง {first} ถึง {last} จากข้อมูล {totalRecords}"
      class="p-datatable-gridlines p-datatable-striped p-datatable-responsive-demo"
    >
      <Column
        field="idx"
        header="ลำดับ"
        :sortable="true"
        style="text-align: center; width: 10%"
      ></Column>
      <!--<Column field="areaName" header="ชื่อพื้นที่" :sortable="true"></Column>-->
      <Column
        field="actMenu"
        header="ชื่อเมนูที่ใช้งาน"
        :sortable="true"
      ></Column>
      <Column
        field="actDtmStr"
        header="วันเวลาที่ใช้งาน"
        :sortable="true"
      ></Column>
      <Column
        field="clientIp"
        header="เลขเครือข่ายอุปกรณ์"
        :sortable="true"
      ></Column>
    </DataTable>
  </Fieldset>
</template>

<script>
export default {
  name: 'activityHistory',
  layout: 'Private',
  computed: {
    activities() {
      const activities = this.$store.state.activity.activities
      return activities.map((act, idx) => {
        const { area, actDtm } = act
        return {
          ...act,
          idx: idx + 1,
          areaName: area?.areaName,
          actDtmStr: actDtm
        }
      })
    }
  },
  async created() {
    try {
      const { codeId: userId, userLevel, userName } = this.$store.getters[
        'oauth/session'
      ]
      await this.$store.dispatch('activity/fetchActivities', {
        userId,
        sort: 'DESC'
      })
    } catch (error) {
      console.log(error)
    }
  }
}
</script>

<style>
</style>